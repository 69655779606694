import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="About | 74Studio" desc="Hi. Welcome to 74 Studio! Check out my favorite photos." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Hi. Welcome to 74 Studio!</h1>
        <p>
          DM me via <a href="https://www.instagram.com/74studio.photography/">instagram</a> or <a href="mailto: 74studio.sj@gmail.com">email me</a><br />
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
